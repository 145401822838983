<template>
  <component
    :is="props.type === 'a' ? 'a' : 'button'"
    ref="rootRef"
    v-ripple="ripple"
    :title="props.title"
    :href="props.href"
    :target="props.target"
    :class="classList"
    :style="styleList"
    :type="props.type === 'a' ? '' : 'button'"
    @click="onClick"
    @mousedown="onMousedown"
    @keydown="onKeydown"
    @touchstart="onTouchstart"
  >
    <slot v-if="props.loading" name="loading">
      <span
        class="items-center text-center flex row flex-grow pointer-events-none cursor-none"
        :class="spanClassList"
      >
        <ASpinner :color="matchTextColor" />
      </span>
    </slot>
    <template v-else>
      <div
        ref="blurTargetRef"
        tabindex="-1"
        class="focus-helper absolute w-full h-full top-0 left-0 pointer-events-none transition rounded"
        :class="{ 'rounded-full': props.round }"
      ></div>
      <span
        class="items-center text-center flex row flex-grow a-anchor--skip"
        :class="spanClassList"
      >
        <AIcon
          v-if="props.icon"
          :class="{ 'mr-2': !props.fab && !props.round && props.label }"
          :style="{ fontSize, color: matchTextColor }"
          >{{ props.icon }}</AIcon
        >
        <span v-if="props.label" class="block">{{ props.label }}</span>
        <AIcon
          v-if="props.iconRight"
          :class="{ 'ml-2': !props.fab && !props.round && props.label }"
          :style="{ fontSize, color: matchTextColor }"
          >{{ props.iconRight }}</AIcon
        >
        <slot></slot>
      </span>
    </template>
  </component>
</template>
<script lang="ts" setup>
import {Key} from "@avvoka/shared";
import { computed, getCurrentInstance, onBeforeUnmount, ref } from 'vue'
import AIcon from './AIcon.vue'
import ASpinner from './ASpinner.vue'
import { vRipple } from './directives/Ripple'
import { resolveColor, resolveTextColor } from './utils'
import { listenOpts, prevent, stopAndPrevent } from './utils/event'
import { isKeyCode } from './utils/key-composition'

const props = withDefaults(
  defineProps<{
    // Put button into loading state (displays a ASpinner -- can be overridden by using a 'loading' slot)
    loading?: boolean

    // Put component in disabled mode
    disable?: boolean

    // The text that will be shown on the button
    label?: string | number

    // The text that will be shown in the tooltip
    title?: string

    // Icon name
    icon?: string

    // Icon name
    iconRight?: string

    // Avoid turning label text into caps (which happens by default)
    noCaps?: boolean

    // Avoid label text wrapping
    noWrap?: boolean

    // Label or content alignment
    align?: string

    // When used on flexbox parent, button will stretch to parent's height
    stretch?: boolean

    type?: 'button' | 'a' | 'submit'

    // Tabindex HTML attribute value
    tabIndex?: number | string

    // Native <a> link href attribute; Type needs to be 'a'
    href?: string

    // Native <a> link href attribute; Type needs to be 'a'
    target?: '_blank' | '_self' | '_parent' | '_top'

    // Standard size name
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'

    // Use 'outline' design
    outline?: boolean

    // Use 'flat' design
    flat?: boolean

    // Remove shadow
    unelevated?: boolean

    // Applies a more prominent border-radius for a squared shape button
    rounded?: boolean

    // Makes a circle shaped button
    round?: boolean

    // Use 'push' design
    push?: boolean

    // Use 'fab' design
    fab?: boolean

    // Apply custom padding
    padding?: String

    color?: string
    textColor?: string
    shade?: number

    // Dense mode; occupies less space
    dense?: boolean

    ripple?: boolean

    selfCenter?: boolean

    maxWidthFit?: boolean
  }>(),
  {
    type: 'button',
    color: 'primary',
    loading: false,
    ripple: true,
    dense: false,
    fab: false,
    push: false,
    round: false,
    rounded: false,
    unelevated: false,
    flat: false,
    outline: false,
    size: 'md',
    stretch: false,
    align: 'center',
    noCaps: true,
    noWrap: false,
    disable: false,
    selfCenter: false,
    maxWidthFit: false
  }
)

const ripple = computed(() =>
  props.disable || !props.ripple
    ? false
    : {
        keyCodes: props.type === 'a' ? [Key.Enter, Key.Space] : [Key.Enter],
        ...(props.ripple === true ? {} : props.ripple)
      }
)
const classList = computed(() => {
  return {
    'a-button inline-flex col relative items-stretch align-middle transition select-none':
      true,
    'flex-nowrap whitespace-nowrap': props.noWrap,
    uppercase: !props.noCaps,
    'cursor-not-allowed': props.disable,
    shadow: !props.unelevated && !props.flat,
    rounded: !props.flat,
    disabled: props.disable,
    'rounded-full': props.round,
    dense: props.dense,
    'max-w-fit': props.maxWidthFit,
    'self-center': props.selfCenter
  }
})

const spanClassList = computed(() => {
  return {
    'justify-start': props.align === 'left',
    'justify-end': props.align === 'right',
    'justify-center': props.align === 'center',
    'justify-between': props.align === 'between',
    'justify-around': props.align === 'around',
    'justify-evenly': props.align === 'evenly',
    'justify-stretch': props.align === 'stretch'
  }
})

const fontSize = computed(() => {
  switch (props.size) {
    case 'xs':
      return '8px'
    case 'sm':
      return '10px'
    case 'md':
      return '14px'
    case 'lg':
      return '20px'
    case 'xl':
      return '24px'
  }
})

const matchColor = computed(() => resolveColor(props.color, props.shade))

const matchTextColor = computed(() => {
  const color = props.textColor
  if (!color && (props.color === 'primary' || props.color === 'secondary'))
    return resolveTextColor(props.color, props.shade)
  return resolveColor(color, props.shade)
})

const styleList = computed(() => {
  const result: Record<string, unknown> = {
    fontSize: fontSize.value,
    color: matchTextColor.value
  }
  if (!props.flat) {
    if (!props.outline) {
      result.backgroundColor = matchColor.value
    } else {
      result.backgroundColor = 'transparent'
      result.border = `1px solid ${matchColor.value}`
    }
  } else {
    result.backgroundColor = 'transparent'
  }
  return result
})

const emit = defineEmits<{
  (e: 'click', event?: MouseEvent): void
  (e: 'mousedown', event: MouseEvent): void
  (e: 'touchstart', event: TouchEvent): void
  (e: 'keydown', event: KeyboardEvent): void
}>()

const { proxy } = getCurrentInstance()!
const { passiveCapture } = listenOpts
let touchTarget: HTMLElement | undefined,
  keyboardTarget: HTMLElement | undefined,
  mouseTarget: HTMLElement | undefined,
  localTouchTargetEl: HTMLElement | undefined,
  avoidMouseRipple: boolean,
  mouseTimer: NodeJS.Timeout

const onLoadingEvt = (evt?: Event & { aSkipRipple?: boolean }) => {
  if (evt) {
    stopAndPrevent(evt)
    evt.aSkipRipple = true
  }
}

const rootRef = ref<HTMLElement>()
const blurTargetRef = ref()
const onClick = (e?: MouseEvent) => {
  if (props.loading) return onLoadingEvt(e)
  if (props.disable) return
  if (!rootRef.value) return

  if (e !== undefined) {
    if (e.defaultPrevented) return

    const el = document.activeElement!
    // focus button if it came from ENTER on form
    // prevent the new submit (already done)
    if (
      props.type === 'submit' &&
      el !== document.body &&
      rootRef.value.contains(el) === false &&
      // required for iOS and desktop Safari
      !el.contains(rootRef.value)
    ) {
      rootRef.value.focus()

      const onClickCleanup = () => {
        document.removeEventListener('keydown', stopAndPrevent, true)
        document.removeEventListener('keyup', onClickCleanup, passiveCapture)
        rootRef.value != null &&
          rootRef.value!.removeEventListener(
            'blur',
            onClickCleanup,
            passiveCapture
          )
      }

      document.addEventListener('keydown', stopAndPrevent, true)
      document.addEventListener('keyup', onClickCleanup, passiveCapture)
      rootRef.value.addEventListener('blur', onClickCleanup, passiveCapture)
    }
  }

  emit('click', e)
}

const onKeydown = (e: KeyboardEvent) => {
  if (props.loading) return onLoadingEvt(e)
  if (props.disable) return
  if (!rootRef.value) return

  emit('keydown', e)

  if (isKeyCode(e, [Key.Enter, Key.Space]) && keyboardTarget !== rootRef.value) {
    if (keyboardTarget) cleanup()

    if (!e.defaultPrevented) {
      // focus external button if the focus helper was focused before
      rootRef.value.focus()

      keyboardTarget = rootRef.value
      rootRef.value.classList.add('q-btn--active')
      document.addEventListener('keyup', onPressEnd, true)
      rootRef.value.addEventListener('blur', onPressEnd, passiveCapture)
    }

    stopAndPrevent(e)
  }
}

const onMousedown = (e: MouseEvent & { aSkipRipple?: boolean }) => {
  if (props.loading) return onLoadingEvt(e)
  if (props.disable) return
  if (!rootRef.value) return

  e.aSkipRipple = avoidMouseRipple
  emit('mousedown', e)

  if (!e.defaultPrevented && mouseTarget !== rootRef.value) {
    if (mouseTarget) cleanup()
    mouseTarget = rootRef.value
    rootRef.value.classList.add('a-btn--active')
    document.addEventListener('mouseup', onPressEnd, passiveCapture)
  }
}

const onTouchstart = (e) => {
  if (props.loading) return onLoadingEvt(e)
  if (props.disable) return
  if (!rootRef.value) return

  emit('touchstart', e)

  if (e.defaultPrevented) {
    return
  }

  if (touchTarget !== rootRef.value) {
    if (touchTarget) cleanup()
    touchTarget = rootRef.value

    localTouchTargetEl = e.target
    localTouchTargetEl!.addEventListener(
      'touchcancel',
      onPressEnd,
      passiveCapture
    )
    localTouchTargetEl!.addEventListener('touchend', onPressEnd, passiveCapture)
  }

  // avoid duplicated mousedown event
  // triggering another early ripple
  avoidMouseRipple = true
  clearTimeout(mouseTimer)
  mouseTimer = setTimeout(() => {
    avoidMouseRipple = false
  }, 200)
}

const cleanup = (destroying?: boolean) => {
  const blurTarget = blurTargetRef.value

  if (
    destroying !== true &&
    (touchTarget == rootRef.value || mouseTarget == rootRef.value) &&
    blurTarget != null &&
    blurTarget != document.activeElement
  ) {
    blurTarget.setAttribute('tabindex', -1)
    blurTarget.focus()
  }

  if (touchTarget === rootRef.value) {
    if (localTouchTargetEl != null) {
      localTouchTargetEl.removeEventListener(
        'touchcancel',
        onPressEnd,
        passiveCapture
      )
      localTouchTargetEl.removeEventListener(
        'touchend',
        onPressEnd,
        passiveCapture
      )
    }
    touchTarget = localTouchTargetEl = undefined
  }

  if (mouseTarget === rootRef.value) {
    document.removeEventListener('mouseup', onPressEnd, passiveCapture)
    mouseTarget = undefined
  }

  if (keyboardTarget === rootRef.value) {
    document.removeEventListener('keyup', onPressEnd, true)
    rootRef.value != null &&
      rootRef.value.removeEventListener('blur', onPressEnd, passiveCapture)
    keyboardTarget = undefined
  }

  rootRef.value != null && rootRef.value.classList.remove('q-btn--active')
}

const onPressEnd = (e?: Event) => {
  if (!rootRef.value) return

  // needed for IE (because it emits blur when focusing button from focus helper)
  if (
    e !== undefined &&
    e.type === 'blur' &&
    document.activeElement === rootRef.value
  ) {
    return
  }

  if (e !== undefined && e.type === 'keyup') {
    if (keyboardTarget === rootRef.value && isKeyCode(e, [Key.Enter, Key.Space])) {
      // for click trigger
      const evt = new MouseEvent('click', e)
      evt.aKeyEvent = true
      e.defaultPrevented && prevent(evt)
      e.cancelBubble && stop(evt)
      rootRef.value.dispatchEvent(evt)

      stopAndPrevent(e)

      // for ripple
      e.aKeyEvent = true
    }

    emit('keyup', e)
  }

  cleanup()
}

onBeforeUnmount(() => {
  cleanup(true)
})
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({name: "AButton"})
</script>
<style lang="scss">
.a-button {
  padding: 4px 16px;
  min-height: 2.572em;
  font-family: 'Roboto', sans-serif;

  &.dense {
    padding: 0.285em;
    min-height: 2em;
  }

  &.rounded-full {
    padding: 0;
    min-height: 2.4em;
    min-width: 2.4em;
  }

  .focus-helper {
    opacity: 0;
    background-color: currentColor;
  }

  &:not(.disabled) {
    &:hover > .focus-helper {
      opacity: 0.2;
    }
  }

  &.disabled {
    opacity: 0.7 !important;
  }
}
</style>
